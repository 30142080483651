.subcontainer{
    height: 1200px!important;
}
.currency{
    font-size:4.0em;
    font-weight: bold;
    color: #00abbe;
  }
  
  .price{
    font-size:.5em;
    vertical-align:super;
  }

  .bold{
    font-weight: bold;
  }

  .sub-border{
    border-color: #00abbe;
    border-width: 2px;
  }

  .theme-color{
    color: #00abbe;
  }

  .card-header{
    background-color: #00abbe;
    color: white;
  }