#loader-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* / Tick Section Animation / */
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #ffffff;
    stroke-miterlimit: 100;
    box-shadow: inset 0px 0px 0px #00abbe;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #00abbe;
    fill: #00abbe;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #00abbe;
    }
}

/* / Loading Section and Animation Around Logo / */

.progress-bar,
.tick-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.progress-bar {
    background:
        radial-gradient(closest-side, white 90%, transparent 50% 100%),
        conic-gradient(#00abbe calc(var(--progress-value) * 1%), #ffffff 0);
    animation: progress 4s infinite forwards;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

.loader>img,
.progress-bar>img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

@property --progress-value {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

@keyframes progress {
    to {
        --progress-value: 100;/* Loading Section Value*/
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: grid;
    background-color: rgb(255 255 255 / 8%);
    backdrop-filter: blur(5px);
  }

/* Failer Pop*/


.loader-new-fail {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3px;
    transition: all 400ms ease-in-out;
}


.loader-new-fail .errormark:before,
.loader-new-fail .errormark:after {
    margin: 1px;
    content: '';
    opacity: 1;
    height: 0;
    width: 0;
    transform-origin: center top;
    border-right: 12px solid #fff;
    top: 2.4em;
    position: absolute;
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: loader-errormark;
    animation-fill-mode: forwards;
}

.loader-new-fail .errormark:before {
    transform: rotate(-45deg);
    left: 2em;
}

.loader-new-fail .errormark:after {
    animation-delay: 400ms;
    transform: rotate(45deg);
    right: 2em;
}

.loader-new-fail .errormark {
    display: block;
    animation: infinite;
}

@keyframes loader-errormark {
    0% {
        height: 0;
    }

    100% {
        height: 4em;
    }
}
