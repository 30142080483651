.tableTitle {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px;
  
    img {
      margin-left: 16px;
    }
  }

  .checkbox {
    padding-left: 20px;
    display: inline-block;
    margin-bottom: 4px;
  }

  .blackColor {
    color: #313D47;
}

.searchable__controls{
  background-color: #ffffff;
  border: 3 px;
}