@import "../../styles/helpers";

.footer {
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 24px;
  right: 24px;
  color: $base-dark-gray;
  font-size: $font-size-sm;
}

.footerLabel {
  margin-right: 4px;
}
