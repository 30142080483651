@import "../../styles/helpers";

.root {
  display: flex;
  flex-direction: column;
  width: $sidebar-width-open;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-left: 2px;
  background-color: $sidebar-color;
  overflow-y: auto;
  transition: height 1s;
  // transform: translateX(-$sidebar-width-open);
  
  ul li{
    /*
    &:nth-child(9){
      position: absolute;
      bottom: 3.25em;
      width: 12.5em;

    }
    */
    &:nth-child(10){
      position: absolute;
      bottom: 3.25em;
      width: 12.5em;
    }

    &:nth-child(11){
      position: absolute;
      bottom: 1.25em;
      width: 13.5em;
    }
   
  }
  @media print {
    display: none;
  }

  :global(.sidebar-hide) & {
    display: none;
  }

  @media (max-width: breakpoint-max(md)){
    ul li{
      /*
      &:nth-child(9){
        position: relative;
        bottom: auto;
        width: auto;
      }
      */
      &:nth-child(10){
        position: relative;
        bottom: auto;
        width: auto;
      }
      &:nth-child(11){
        position: relative;
        bottom: auto;
        width: auto;
      }
    }
  }
  @media (max-width: breakpoint-max(sm)) {
    position: static !important;
    width: auto;
    margin-bottom: 12px 24px 0;
    border-radius: 8px;
    padding: 0;
    height: 0;
    transform: none;

    // .logo,
    .sidebarAlerts,
    .sidebarLabels,
    .navTitle {
      display: none;
    }
  }
}
.link-a{
  padding: 10px 16px;
}
.sidebarOpen {
  @media (min-width: breakpoint-min(md)) {
    height: auto !important;
  }
}
.practicelogo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    height: 60px;
    width: 60px;
}

.logo {
  display: flex;
  align-items: center;
  margin: 16px 0 24px;
  width: 100%;
  justify-content: center;

  .title {
    margin-left: 12px;
    font-size: 20px;
    font-weight: $font-weight-bold;
  }
}

.nav {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  li > a > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }
}

.navTitle {
  margin: 32px 0 8px;
  font-size:$font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
  text-transform: uppercase;
}

.feedbackmodal{
  min-width: 70%;
}

.headStyle{
  text-align: center;
  color: #00abbe;
}

@media (max-width:1170px){
   .root {
//  display: none !important;
position: static !important;
width: auto;
margin: 12px 24px 0;
border-radius: 8px;
padding: 0;
height: 0;
transform: none;

}}


