.tableTitle {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px;
  
    img {
      margin-left: 16px;
    }
  }

  .blackColor {
    color: #313D47;
}