@import "../../styles/helpers";

.widgetBlock {
//  padding: 12px 0;
  background-color: none;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.widgetBody1 {
  display: flex;
  align-items: center;
  padding: 0 16px;
  padding-left: 3px;
}
.calendarIcon {
  font-size: 30px !important;
  padding-top: 5px;
  padding-right: 5px;
}
.calendar {
  font-size: 30px !important;
  text-indent: -9999px;
  white-space: nowrap;
  outline: none;
  border: 0;
  width: 20%;
  margin-right: 11px;
}
.headStyle {
  color: #00abbe;
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .mutedPink {
    background-color: #ff566830;
  }

  .mutedYellow {
    background-color: #ffc40530;
  }

  .mutedTeal {
    background-color: #41d5e230;
  }

  .mutedViolet {
    background-color: #4d53e030;
  }
  //  доделать для остальных цветов прогресс баров
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 24px;
  }

  .pieImg {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.responsiveWidget {
  @media (max-width: breakpoint-max(lg)) {
    padding: 24px 12px;
  }
}

.justifyeven {
  align-items: center !important;
  .moveright{
    margin-left: auto;
    margin-right: 10px;
  }
}

.brdrcolor{
  border-color: #00abbe;
}

.errortext
{
  color: red !important;
}

.calendarday{
  font-size: 16px;
  font-weight: 600;
  color: #00abbe;
  margin-left: 6px;
}

.caltodayfont{
  font-size: 8.5px !important;
  left: 15.8px !important;
}